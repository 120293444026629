import React from 'react';
import './style.scss';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage"
import Wrapper from '../Wrapper/Wrapper';
import PaginatedScroll from '../PaginatedScroll/PaginatedScroll';
import SEO from '../../components/seo';

const PodcastsHome = ({pageContext}) => {

    const decodedTitle = decodeURIComponent(pageContext.homepage.title);
    
    return (
        <Wrapper pageCurrent={ `podcast-home ${pageContext.homepage.title.toLowerCase()}` }  footerPattern={true}>
            <SEO title={decodedTitle} />

            <div className="blockwrap menu-spacer">
                {/* <img className="featured" src={pageContext.thisBlogEdge.featured_media.source_url} alt="featured" /> */}
                <FeaturedImage pageContext={pageContext.homepage} /> 
            </div>
            <div className="blockwrap">
                
                <div className="container base-styles">
                
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline" dangerouslySetInnerHTML={{__html: pageContext.homepage.title}}></h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.homepage.content}} />

                    </div>
                    
                </div>
            
            </div>

            <div className="blockwrap">
                <div className="container base-styles">
                    <div className="content">
                        <h1 className="animate-underline">Podcast Episodes</h1>
                        <p>You can listen to our podcast right here on our website. The episodes are also available on Google Podcasts or iTunes.</p>
                        <br />
                        <br />
                    </div>
                </div>
                
               
                
                <PaginatedScroll pageContext={pageContext} paginatedType="podcasts" />
                    
            </div>
        
        </Wrapper>

    )

}
export default PodcastsHome;
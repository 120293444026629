import React from "react";
import PropTypes from "prop-types";
import './style.scss';
import { Link } from "gatsby";
//import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
//import theme from "../theme.yaml"

const Pagination = props => {
console.log('pagination propps ',props);
    const { currentPage, countPages, paginatedType } = props.paginationData;
    const isFirst = currentPage === 1 || !currentPage;
    const isLast = currentPage === countPages;
    const prevPage = "/" + (currentPage - 1 > 1 ? (currentPage - 1) : "");
    const nextPage = "/" + (currentPage + 1);
    //const verticalAlignment = { paddingTop: "0.25em" }

    var visiblePageNumbers = selectRelevantPageLinks(currentPage, countPages)

    return (
        <React.Fragment>
            <div className="pagination">

                {/* "Prev" arrow */}
                {!isFirst && (
                    <Link to={`/resources/community-blog/${prevPage}`} rel="prev"  >
                        <span className="prev-arrow">
                            {/* <FaArrowLeft/> */}
                            <i className="fa fa-long-arrow-left"></i>
                        </span>
                    </Link>
                )}

                {/* Numbered page links. */}
                {countPages > 1 && (
                    <React.Fragment>
                        {visiblePageNumbers.map(num => {
                            if (isNaN(num)) {
                                return <span key={`dots-${num}`}>.....</span>
                            }
                            return (
                                <span className="pagination-numbers" key={`page-${num}`} >
                                    <Link
                                        to={`/resources/${paginatedType}/${num === 1 ? "" : num}`}
                                        style={{
                                             
                                             
                                           
                                            
                                        }}
                                        className={`pagination-numbers ${num === currentPage ? "active" : null}`}
                                    >
                                        {num}
                                    </Link>
                                </span>
                            )
                        })}
                    </React.Fragment>
                )}

                {/* "Next" arrow */}
                {!isLast && (
                    <Link to={`/resources/${paginatedType}/${nextPage}`} rel="next"  >
                        <span className="next-arrow">
                        <i className="fa fa-long-arrow-right"></i>
                           {/*  <FaArrowRight/> */}
                        </span>
                    </Link>
                )}


            </div>
            
        </React.Fragment>
    );
}

function selectRelevantPageLinks(currentPage, countPages) {
    var visiblePageNumbers = []
    if (countPages <= 10) {
        /* If there are not too much, show everything. */
        for (let i=1; i<=countPages; i++) {
            visiblePageNumbers.push(i)
        }
    } else {
        /* Always show beginning, end, current, and around current. */
        if (currentPage <= 5) {
            /* If beginning and current are not too far, we don't want to "dot dot" between them. */
            for (let i=1; i<currentPage; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(1)
            visiblePageNumbers.push("dots-left-half")
            visiblePageNumbers.push(currentPage-2)
            visiblePageNumbers.push(currentPage-1)
        }
        visiblePageNumbers.push(currentPage)
        if (currentPage >= countPages-4) {
            /* If current and end are not too far, we don't want to "dot dot" between them. */
            for (let i=currentPage+1; i<countPages; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(currentPage+1)
            visiblePageNumbers.push(currentPage+2)
            visiblePageNumbers.push("dots-right-half")
        }
        if (currentPage !== countPages) {
            visiblePageNumbers.push(countPages)
        }
    }
    return visiblePageNumbers
}

Pagination.propTypes = {
    paginationData: PropTypes.object.isRequired
};

export default Pagination;
import React from "react"
import './style.scss'
import { Link } from "gatsby"
import Img from "gatsby-image"
import circleMask from "../../../assets/images/circle-mask.png";

const ListPodItem = props => {
    //console.log('ListItem props ',props);
    return (
        <React.Fragment>

            <div className="container list-item-wrap">
                <div className="content">

                    <div className="list-item" key={props.index}>
                        
                        
                        {props.item && props.js && (
                            <React.Fragment>
                                <div className="ft-img-wrap">
                                    <img src={circleMask} className="circle-mask" alt="circle masking out" />
                                    <Img fluid={props.item.post.featured_media.mobile_crop.childImageSharp.fluid} className="clip-circle" alt="Featured Post" />
                                </div>
                                <div className="info">
                                    <h2>
                                        <Link to={`/resources/podcasts/${props.item.post.slug}`}>
                                            <span dangerouslySetInnerHTML={{__html: props.item.post.title }} />
                                        </Link>
                                    </h2>
                                    <h4>{props.item.post.author.name}, {props.item.post.date}</h4>
                                    <div dangerouslySetInnerHTML={{__html: props.item.post.excerpt }}></div>
                                    <Link to={`/resources/podcasts/${props.item.post.slug}`} className="read-more">
                                        {/* <img src={props.item.s.src} alt="" title="" /> */}
                                        Listen Now<i className="fa fa-long-arrow-right"></i>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                        {props.item && !props.js && (
                            
                            <React.Fragment>
                                <div className="ft-img-wrap">
                                    <img src={circleMask} className="circle-mask" alt="circle masking out" />
                                    <Img fluid={props.item.post.featured_media.mobile_crop.childImageSharp.fluid} className="clip-circle" alt="Featured Post"  />
                                </div>
                                <div className="info">
                                    <h2>
                                        <Link to={`/resources/podcasts/${props.item.post.slug}`}>
                                            <span dangerouslySetInnerHTML={{__html: props.item.post.title }} />
                                        </Link>
                                    </h2>
                                    <h4>{props.item.post.author.name}, {props.item.post.date}</h4>
                                    <div dangerouslySetInnerHTML={{__html: props.item.post.excerpt }}></div>
                                    <Link to={`/resources/podcasts/${props.item.post.slug}`} className="read-more btn-basic">
                                        {/* <img src={props.item.s.src} alt="" title="" /> */}
                                        Listen Now<i className="fa fa-long-arrow-right"></i>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                        
                    </div>

                </div>
            </div>

            <style jsx>
                {`
                    .post-container {
                        position: relative;
                        width:100%;
                        background:#EEE;
                        border-radius: 5px;
                        border: 1px solid ghostwhite;
                        overflow: hidden;
                        z-index: 1;
                    }

                    .img-container::before {
                        content: '';
                        display: block;
                        margin-top: 100%;
                        z-index: 1;
                    }

                    .img-container img {
                        top: 0;
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: auto;
                        margin: 0 !important;
                        border-radius: 5px;
                        border: 1px solid gray;
                        transition: 0.2s ease-in-out;
                        z-index: 2;

                        :hover {
                            opacity: 0.4;
                            transform: scale(1.1);
                        }
                    }
                `}
            </style>
        </React.Fragment>
    )
}

export default ListPodItem
import React from "react";

import { GlobalStateContext } from '../../providers/GlobalState/GlobalState';

import View from "./View"


class PaginatedScroll extends React.Component {
    
    render() {
         
        return (
            
            <GlobalStateContext.Consumer>
                {globalState => (
                    <>
                        
                        <View globalState={globalState} pageContext={this.props.pageContext} paginatedType={this.props.paginatedType} />
                    </>
                )}
            </GlobalStateContext.Consumer>
        
        )
    }
}
export default PaginatedScroll;